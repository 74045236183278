import React from "react"
import { graphql, Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/SEO"
import BlogCategories from "../components/blogCategories"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export default ({ data }) => (
  <div>
    <SEO
      pageTitle="Kalukanda Editorial"
      pageDescription="Kalukanda House is more than a destination, it’s a community of creativity and a celebration of design, wellbeing, nature and the best of Sri Lanka. There is nothing we love more than sharing inspiration and ideas with conscious travellers to enhance their lives - at home or away."
    />
    <Header headerText="Home Page" />
    <Container fluid className="blog">
      <div className="experiences">
        <h1 className="title">Kalukanda Editorial</h1>
        <p className="description">
          Kalukanda House is more than a destination, it’s a community of
          creativity and a celebration of design, wellbeing, nature and the best
          of Sri Lanka. There is nothing we love more than sharing inspiration
          and ideas with conscious travellers to enhance their lives - at home
          or away.{" "}
        </p>
        <BlogCategories active={false} />
        <Row>
          {data.allMarkdownRemark.edges.map(({ node }, index) => (
            <Col md={4}>
              <Link to={node.frontmatter.path}>
                <Img
                  className="image-block"
                  fluid={
                    node.frontmatter.photo
                      ? node.frontmatter.photo.childImageSharp.fluid
                      : null
                  }
                  alt={node.frontmatter.photo ? node.frontmatter.photo.alt : ""}
                  title={
                    node.frontmatter.photo ? node.frontmatter.photo.title : ""
                  }
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
                <h3>{node.frontmatter.title}</h3>
                <div>{node.frontmatter.date}</div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
    <Footer />
  </div>
)

export const query = graphql`
  query allBlogsQuery {
    allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          html
          excerpt(pruneLength: 420)
          fields {
            slug
            contentType
          }
          frontmatter {
            description
            title
            category
            path
            date(formatString: "MMMM YYYY")
            photo {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
